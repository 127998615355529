<template>
    <div>
      <vue-element-loading
        :active="appLoading"
        spinner="bar-fade-scale"
        color="#1976d2"
        size="128"
        :is-full-screen="false"
      />
      <v-snackbar v-model="showsnackbar" color="black" right>
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showsnackbar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout v-if="ServerError" wrap justify-center>
        <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
          <ServerError />
        </v-flex>
      </v-layout>
      <v-layout wrap v-else>
        <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
          <v-card>
            <v-card-title class="elevation-1">
              <span>Common Image</span>
              <v-spacer></v-spacer>
              <!-- <v-flex pt-5 xl3 lg3 md3 pb-2 sm3 xs3 px-5>
                <v-dialog
                  :retain-focus="false"
                  v-model="dialog"
                  persistent
                  max-width="1000px"
                  :key="dialog"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined color="red" v-bind="attrs" v-on="on">
                      Add
                    </v-btn>
                  </template>
                  <v-card>
                    <v-form v-model="addslider">
                      <v-card-title>
                        <span class="headline">Add</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" sm="12" md="12">
                              <v-text-field
                                v-model="title"
                                label="Title"
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                              <v-text-field
                                v-model="caption"
                                label="Caption"
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <ImageComp
                                :singleImage="mainImagePreview"
                                @stepper="winStepper"
                                :heading="'Upload Image'"
                                :componentType="'mainImage'"
                              />
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="dialogclose()">
                          Close
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          :disabled="!addslider"
                          @click="add()"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-form>
                  </v-card>
                </v-dialog>
              </v-flex> -->
              <!-- <v-flex pt-5 xl3 lg3 md3 pb-2 sm3 xs3 px-5>
                <v-dialog v-model="dialogDelete" max-width="600px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      class="mr-2"
                      outlined
                      v-on="on"
                      v-bind="attrs"
                      color="red"
                    >
                      Delete
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title
                      >Are you sure you want to delete this Careers?</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="dialogDelete = false"
                        >Cancel</v-btn
                      >
                      <v-btn color="blue darken-1" text @click="deleteItem()"
                        >OK</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-flex> -->
            </v-card-title>
            <div>
              <v-layout wrap>
                <!-- <v-flex xs12 pt-5 v-if="title">
                  <v-text-field
                    outlined
                    v-model="title"
                    label="Title"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 pt-5>
                  <v-text-field
                    outlined
                    v-model="caption"
                    label="Caption"
                  ></v-text-field>
                </v-flex> -->
                <v-flex xs12 pt-5>
                  <ImageComp
                    :singleImage="mainImagePreview"
                    @stepper="winStepper"
                    :height="'150'"
                    :width="'300'"
                    :heading="'Upload Image (300 X 150)'"
                    :componentType="'mainImage'"
                  />
                </v-flex>
              </v-layout>
              <v-layout pt-5 pb-5 wrap justify-end>
                <v-flex xs6 md2 v-if="staticArray != null">
                  <v-btn
                    block
                    tile
                    color="green"
                    dark
                    :ripple="false"
                    style="text-transform: none"
                    depressed
                    @click="uploadImage()"
                  >
                    Save Changes
                  </v-btn>
                </v-flex>
                <v-flex v-else>
                  <v-btn outlined color="red" @click="uploadImage()"> Add </v-btn>
                </v-flex>
              </v-layout>
            </div>
            <!-- <div v-else>
              <h4 style="padding-top: 30px">No data avilable.....</h4>
            </div> -->
          </v-card>
        </v-flex>
      </v-layout>
    </div>
  </template>
  <script>
  import axios from "axios";
  import ImageComp from "@/components/commonComponents/singleImage";
  export default {
    components: {
      ImageComp,
    },
    data() {
      return {
        checkbox: true,
        ServerError: false,
        showsnackbar: false,
        editingitem: [],
        caption: "",
        file: null,
        coverFile: null,
        mainImagePreview: "",
        productImagePreview: "",
        image: "",
        mainImage: "",
        productImage: "",
        msg: null,
        pages: 0,
        Value: null,
        title: null,
        widthOfCard: "250px",
        appLoading: false,
        currentPage: 1,
        totalData: 0,
        totalRows: 0,
        dialogDelete: false,
        search: "",
        dialog: false,
        editdialog: false,
        staticArray: [],
        addslider: false,
        nameRules: [
          (v) => !!v || "Name is required",
          (v) => (v && v.length <= 15) || "Name must be less than 15 characters",
        ],
        Rules: [(value) => !!value || "Required."],
      };
    },
    beforeMount() {
      this.getData();
    },
    methods: {
      winStepper(window_data) {
        if (window_data.type == "mainImage") {
          this.file = window_data.selectedFiles;
        }
      },
      getData() {
        this.appLoading = true;
        axios({
          url: "/brandstory/info/image/list",
          method: "GET",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        })
          .then((response) => {
            this.appLoading = false;
            this.staticArray = response.data.data;
            this.mainImagePreview = this.staticArray.image;
          })
          .catch((err) => {
            this.appLoading = false;
            console.log(err);
          });
      },
      add() {
        var data = {};
        // data["title"] = this.title;
        // data["caption"]=this.caption
        axios({
          url: "/story/page/set",
          method: "POST",
          data: data,
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status) {
              if (this.file) {
                this.uploadImage();
              } else {
                this.getData();
              }
              this.msg = "Added Sucessfully";
              this.showsnackbar = true;
            } else {
              this.msg = response.data.msg;
              this.showsnackbar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.$emit("stepper", {
              ServerError: true,
            });
            console.log(err);
          });
      },
      uploadImage() {
        let formData = new FormData();
        formData.append("image", this.file);
        formData.append("id", this.staticArray._id);
        axios({
          method: "POST",
          url: "/upload/brandstory/info/image",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            "x-auth-token": localStorage.getItem("token"),
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              this.appLoading = false;
              this.showsnackbar = true;
              this.msg = "Uploaded Successfully";
              this.getData();
            } else {
              this.appLoading = false;
              this.msg = response.data.msg;
              this.showsnackbar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      dialogclose() {
        this.link = null;
        this.dialog = false;
      },
      edit() {
        this.appLoading = true;
        var data = {};
        data["id"] = this.staticArray._id;
        // data["title"] = this.title;
        // data["caption"] =this.caption
        axios({
          method: "POST",
          url: "/story/page/set",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          data: data,
        })
          .then((response) => {
            this.appLoading = false;
            this.editdialog = false;
            if (response.data.status) {
              this.msg = "Edited Sucessfully";
              this.showsnackbar = true;
              this.dialog = false;
              if (this.file) {
                this.uploadImage();
              } else {
                this.getData();
              }
            } else {
              this.msg = response.data.msg;
              this.showsnackbar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
    },
  };
  </script>
  